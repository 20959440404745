export default defineNuxtRouteMiddleware((from, to) => {
    const auth = useAuthStore();
    const toast = useCustomToast();
    const { t } = useNuxtApp().$i18n;
    const permissions = [...(from.meta.permissions ?? []), ...(to.meta.permissions ?? [])].filter(Boolean);

    if (permissions) {
        for (const permission of permissions as string[]) {
            if (!auth.hasPermission(permission)) {
                toast.add({
                    title: t("notification.noAccessPage"),
                    color: "red"
                });

                if (from.path === to.path) {
                    return navigateTo("/");
                }

                return false;
            }
        }
    }
});
